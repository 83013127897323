.devices-container {
	display: flex;
}

.reading-controls {
	width: 30%;
	margin: 0 1rem 1rem 2rem;
	padding: 1rem;
	background-color: var(--background-color);
	border-radius: var(--border-radius);
}

.reading-controls .device-search {
	width: 100%;
	margin-bottom: 2rem;
	padding: 0.25rem 1rem;
	background-color: white;
	border-radius: 2rem;
}

.reading-controls .filter-title {
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.reading-controls .filter {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
}

.reading-controls .filter label {
	display: flex;
	gap: 5px;
}

.devices-container .content {
	height: calc(100vh - 86px);
	width: 70%;
	padding-left: 1rem;
}

.device {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(var(--blue-02));
	border-radius: var(--border-radius);
	transition: var(--tran-03);
}

.info {
	padding: 1rem 1rem 0.5rem;
	cursor: pointer;
}

.info:hover {
	background-color: rgba(var(--blue-02), 0.1);
}

.device-name {
	font-size: 1.5rem;
	font-weight: 500;
}

.info .device-loc {
	margin-bottom: 5px;
	font-size: 0.9rem;
}

.view-readings {
	margin-bottom: 0.5rem;
	padding: 0 1rem;
	display: flex;
	align-items: center;
	gap: 1rem;
	font-size: 0.75rem;
	color: gray;
	cursor: pointer;
	transition: var(--tran-02);
}

.view-readings span {
	white-space: nowrap;
	transition: var(--tran-02);
}

.view-readings .line {
	width: 100%;
	height: 1px;
	background-color: var(--gray-accent);
	transition: var(--tran-02);
}

.view-readings .icon {
	height: 1rem;
	width: 1rem;
	display: grid;
	place-items: center;
	font-size: 1rem;
	background-color: transparent;
	transition: var(--tran-02);
}

.view-readings:hover {
	color: rgba(var(--blue-03));
}

.view-readings:hover .line {
	background-color: rgba(var(--blue-03));
}

.view-readings-checkbox {
	display: none;
}

.readings {
	max-height: 0;
	opacity: 0;
	transition: var(--tran-03);
	pointer-events: none;
}

.readings table {
	display: none;
	border: unset;
}

.readings tr {
	border: unset;
}

.readings td {
	padding: unset;
	font-size: 0.8rem;
}

.readings td:last-child {
	font-weight: 500;
	text-align: right;
}

.view-readings-checkbox:checked ~ .readings {
	max-height: 203px;
	margin-bottom: 1rem;
	padding: 0 1rem;
	opacity: 1;
	transition: var(--tran-02-delayed-10);
}

.view-readings-checkbox:checked ~ .readings table {
	display: table;
}

@media only screen and (width < 545px) {
	.devices-container {
		flex-direction: column;
	}

	.reading-controls {
		width: calc(100% - 4rem);
		margin: 0 2rem 1rem;
		padding: 0.5rem 1rem;
	}

	.reading-controls .device-search {
		margin-bottom: 1rem;
	}

	.reading-controls .filter {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		column-gap: 5px;
		font-size: 0.8rem;
	}

	.devices-container .content {
		width: 100%;
		height: calc(100vh - 249px);
		padding: 0 2rem;
	}
}
