.login-container {
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;
}

.login {
    width: 350px;
    padding: 3rem 1.5rem;
    box-shadow: var(--shadow-02);
}

.login .logo {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    display: grid;
    place-items: center;
    background-color: rgba(var(--blue-02), 0.2);
    border-radius: 50%;
}

.login h1 {
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-align: center;
}

.login .invalid {
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: rgba(var(--red));
    background-color: rgba(var(--red), 0.1);
    border: 1px solid rgba(var(--red));
    border-radius: var(--border-radius);
    transition: var(--tran-02);
    opacity: 1;
}

.invalid > * {
    transition: var(--tran-02-delayed-10);
}

.invalid .icon {
    margin-right: 0.65rem;
    font-size: 1.2rem;
}

.invalid.hidden {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

.invalid:not(.hidden) ~ form {
    margin-top: 1.5rem;
}

.login form {
    margin-top: 1rem;
    transition: var(--tran-02);
}

.login-input {
    margin-bottom: 1rem;
    position: relative;
}

.login-input .input-icon {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: grid;
    place-items: center;
    color: var(--gray-accent);
}

.login-input input {
    height: 40px;
    width: 100%;
    padding: 0.25rem 0 0.25rem calc(30px + 0.5rem);
    border-radius: var(--border-radius);
    border: 1px solid var(--gray-accent);
}

.login-input input::placeholder {
    color: var(--gray-accent);
}

.login-input input:focus {
    border-color: rgba(var(--blue-02));
}

.login-input input:focus ~ .input-icon {
    color: rgba(var(--blue-02));
}

.login .btn {
    width: 100%;
    justify-content: center;
}

/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (width < 480px) {
    .login {
        height: 100%;
        width: 100%;
    }
}
