/* Header */

.nav {
	padding: 1rem 2rem;
	margin-bottom: 0.25rem;
	display: flex;
	align-items: center;
	gap: 2rem;
}

.nav .brand {
	display: flex;
	align-items: center;
	gap: 0.75rem;
}

.logo {
	height: 1.25rem;
	width: 1.25rem;
}

.logo img {
	width: 100%;
}

.nav .title {
	font-weight: 600;
}

.toggle.icon {
	padding: 0.75rem 0;
	display: grid;
	place-items: center;
	cursor: pointer;
	background-color: white;
	border-radius: var(--border-radius);
}

.floating.toggle.icon {
	padding: 0.75rem;
	position: absolute;
	top: 1rem;
	left: 1.25rem;
	box-shadow: var(--shadow-02);
	z-index: 1;
}

.nav .toggle:hover {
	color: rgb(var(--blue-03));
}

/* Nav Links */

.nav .nav-items {
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 1rem;
}

.nav-items a {
	font-weight: 400;
	color: var(--gray-accent);
	transition: var(--tran-03);
}

.nav-items a:hover {
	color: rgb(var(--blue-03));
}

.nav-items a.active {
	color: rgb(var(--blue-03));
}

/* Status Indicator */

.status-indicator {
	margin-left: auto;
	padding: 0.6rem 1rem;
	display: flex;
	align-items: center;
	font-weight: 600;
	border-radius: var(--border-radius);
}

.status-indicator .icon {
	margin-right: 0.5rem;
}

@media only screen and (width < 505px) {
	.nav {
		flex-wrap: wrap;
		row-gap: 0;
	}

	.nav-items {
		width: 100%;
		justify-content: center;
	}
}
