/* Search */

.search {
    min-height: min-content;
    width: calc(45% - 4rem);
    padding: 0.75rem 1.5rem;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: var(--shadow-02);
    transition: var(--tran-05);
    z-index: 1;
}

.react-autosuggest__container {
    position: relative;
}

.search-input {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;
}

.search-input .icon {
    color: gray;
    transition: var(--tran-02);
}

.search-input input:focus ~ .icon {
    color: rgb(var(--blue-02));
}

.react-autosuggest__input {
    width: 100%;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__suggestion .suggestions {
    margin-top: 2px;
    padding: 2px 0;
    display: grid;
    grid-template-columns: 21px 1fr;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.highlighted.suggestions {
    background-color: rgba(var(--blue-02), 0.3);
}

.suggestions .icon {
    width: 25px;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
}

/* Form Input */

.form .react-autosuggest__suggestions-list {
    width: 100%;
    position: absolute;
    background-color: white;
    border: 1px solid var(--gray-accent);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    z-index: 1;
}

.form .react-autosuggest__container--open .form-input.location {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Small devices (landscape phones, less than 768) */
@media only screen and (width < 768px) {
    .places {
        left: unset;
        right: 1.25rem;
        transform: unset;
        width: calc(100% - 6.5rem);
    }
}
