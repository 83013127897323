/* AQI */

.aqi-container {
	width: calc(45% - 4rem);
	padding: 1rem;
	position: absolute;
	bottom: -210px;
	left: 50%;
	transform: translateX(-50%);
	transition: var(--tran-05);
	border-radius: var(--border-radius);
	backdrop-filter: blur(3px);
	box-shadow: var(--shadow-02);
	overflow: hidden;
	z-index: 1;
}

.aqi-container.open {
	bottom: 1rem;
}

.aqi {
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
}

.aqi .icon {
	margin-right: 1rem;
	font-size: 55px;
}

.aqi .name {
	font-size: 1.5rem;
	font-weight: 600;
}

.aqi .date {
	font-size: 0.9rem;
}