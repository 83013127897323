/* Admin */

.admin {
	padding: 1.5rem 2.5rem;
	overflow: hidden;
}

.admin .header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.admin .header .nav-items {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.admin .header .nav-items a {
	color: lightgray;
}

.admin .header .nav-items a:hover,
.admin .header .nav-items a.active {
	color: rgba(var(--blue-03));
}

.admin .content {
	height: calc(100% - 54px);
	overflow: auto;
}

.admin .content.reports {
	display: flex;
	gap: 1rem;
	overflow: hidden;
}

.admin .content-name {
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* Search */

.admin .device-search {
	margin-left: auto;
	margin-right: 1rem;
	padding: 0.375rem 1.25rem;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	gap: 0.5rem;
	color: var(--black);
	border-radius: var(--border-radius);
	background-color: rgba(var(--blue-03), 0.1);
}

.admin .device-search .icon {
	color: gray;
	transition: var(--tran-02);
}

.admin .device-search input:focus ~ .icon {
	color: rgba(var(--blue-02));
}

/* Table */

.actions-column {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.action-icon {
	font-size: 1.25rem;
}

.action-btn {
	color: rgba(var(--blue-03), 0.4);
	transition: var(--tran-02);
}

.action-btn:hover {
	color: rgba(var(--blue-03));
}

/* Small devices (landscape phones, less than 768px) */
@media only screen and (width < 768px) {
	.admin .header div .btn {
		padding: 0.375rem;
	}

	.admin .header div .btn span {
		display: none;
	}
}

/* X-Small devices (portrait phones, less than 576px) */
@media only screen and (width < 576px) {
	.admin .header {
		gap: 1rem;
		flex-wrap: wrap;
		justify-content: center;
	}

	.admin .header div,
	.admin .device-search input {
		width: 100%;
	}
}

@media only screen and (width < 430px) {
	.admin .card table thead tr th:nth-child(2),
	.admin .card table tbody tr td:nth-child(2) {
		display: none;
	}
}
