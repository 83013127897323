/* Dashboard */

.dashboard {
	display: flex;
	overflow: hidden;
}

.dashboard .content {
	height: calc(100% - 84px);
	padding: 0 2rem;
	overflow: auto;
}

.graph-container {
	height: 100vh;
	width: 0;
	padding: 0;
	background: white;
	box-shadow: var(--shadow-01);
	transition: var(--tran-05);
	z-index: 1;
}

.graph-container > * {
	opacity: 0;
	transition: var(--tran-02);
}

.maps-container {
	height: 100%;
	width: 100%;
	position: relative;
	transition: var(--tran-05);
}

.google-maps {
	height: 100%;
	width: 100%;
}

/* Toggled Open */

.graph-container.open {
	width: 55%;
}

.graph-container.open > * {
	opacity: 1;
	transition: var(--tran-02-delayed-45);
}

.graph-container:is(.open) ~ .maps-container {
	width: 45%;
}

.graph-container:is(.open) ~ .maps-container .search,
.graph-container:is(.open) ~ .maps-container .aqi-container {
	width: calc(100% - 4rem);
	left: unset;
	transform: unset;
	right: 2rem;
}

/* Medium devices (tablets, less than 992px)*/
@media only screen and (width < 940px) {
	.graph-container.open {
		width: 100vw;
	}

	.maps-container {
		width: 100%;
	}

	.graph-container:is(.open) ~ .maps-container {
		width: 0;
	}

	.graph-container:is(.open) ~ .maps-container > * {
		padding: unset;
		border: 0px;
		opacity: 0;
	}

	.graph-container:not(.open) ~ .maps-container .search {
		width: calc(100% - 7.25rem);
		left: 5.25rem;
		transform: unset;
	}

	.graph-container:not(.open) ~ .maps-container .aqi-container {
		width: calc(100% - 4rem);
	}

	.doughnut-graph {
		width: 80%;
	}
}
