#notfound {
    position: relative;
    height: 100vh;
    background-color: white;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 560px;
    width: 100%;
    padding-left: 160px;
    line-height: 1.1;
}

.notfound .notfound-404 {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    background-image: url("../../assets/img/emoji.png");
    background-size: cover;
}

.notfound .notfound-404:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--background-color);
    z-index: -1;
    -webkit-transform: scale(2.4);
    -ms-transform: scale(2.4);
    transform: scale(2.4);
}

.notfound h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 65px;
    font-weight: 700;
    text-transform: uppercase;
    color: #151723;
}

.notfound h2 {
    margin: 0;
    font-size: 21px;
    font-weight: 400;
    text-transform: uppercase;
    color: #151723;
}

.notfound p {
    margin: 1rem 0;
    font-weight: 400;
    color: #999fa5;
}

.notfound a {
    display: inline-block;
    font-weight: 700;
    text-decoration: none;
    color: #388dbc;
    border-radius: 40px;
}

@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
        width: 110px;
        height: 110px;
    }
    .notfound {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 110px;
    }
}
