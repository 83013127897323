/* Reports */

.report-inputs {
	width: 300px;
	height: 100%;
	gap: 1rem;
}

.report-inputs .report-type {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.report-inputs .options {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}

.report-inputs .report-type > span,
.report-inputs .options > span {
	margin-bottom: 0.5rem;
	font-weight: 500;
}

.report-inputs .report-type label,
.report-inputs .report-type label span {
	margin-left: 10px;
}

.report-inputs select {
	padding: 0.375rem 1.25rem;
	border: 1px solid var(--gray-accent);
	border-radius: var(--border-radius);
}

.report-inputs .download {
	margin-top: auto;
	margin-bottom: 0;
}

.report-inputs .download .icon,
.report-inputs .csv .icon {
	font-size: 1rem;
}

.page-container {
	width: calc(100% - 300px);
	overflow: auto;
}

.page {
	width: 8.5in;
	margin: 0 auto;
	padding: 0.25in;
	font-size: 12px;
	background-color: white;
	border: 1px solid var(--gray-accent);
}

.page .header {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}

.page .header .title {
	font-weight: 600;
}

.page .report-details {
	text-align: center;
	margin-bottom: 2rem;
}

.page .content > *:not(:last-child) {
	margin-bottom: 1rem;
}

.page table th,
.page table td {
	border: 1px solid var(--gray-accent);
}
