/* Modals */

.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	transition: var(--tran-05);
	z-index: 2;
}

.modal-content {
	min-width: 400px;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: var(--border-radius);
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
	animation: slide-up 0.5s forwards;
}

.modal-content.empty {
	height: 200px;
	width: 400px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Modal Header */

.modal-header {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1.25rem;
	border-bottom: 1px solid var(--border-color);
}

.modal-header span {
	font-weight: 500;
}

.modal-header .x-icon {
	color: var(--font-color-gray);
	cursor: pointer;
}

.modal-header .x-icon:hover {
	color: var(--font-color-dark);
}

/* Modal Main Body */

.modal-content .modal-body {
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.form-label::after {
	content: "*";
	color: rgba(var(--red));
}

.form-input {
	width: 100%;
	margin: 0.25rem 0 0.75rem;
	padding: 0.25rem 0.5rem;
	border: 1px solid var(--gray-accent);
	border-radius: var(--border-radius);
}

.form-input.invalid {
	width: 100%;
	margin: 0.25rem 0 0.75rem;
	padding: 0.25rem 0.5rem;
	border: 1px solid rgba(var(--red));
	border-radius: var(--border-radius);
}

.form-input.location {
	margin-bottom: unset;
}

.minimap-container {
	height: 200px;
	width: 550px;
	margin-top: 0.75rem;
	position: relative;
}

.minimap.icon {
	position: absolute;
	bottom: 50%;
	right: 50%;
	transform: translateX(50%);
	color: rgba(var(--red));
}

.prompt {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.prompt .icon {
	color: rgba(var(--red));
}

.code {
	height: 250px;
	width: 550px;
}

.code > * {
	height: 100%;
	width: 100%;
	font-family: "CascadiaMono", monospace;
	font-size: 0.75rem;
}

.code > * > * {
	height: 100%;
	width: 100%;
}

/* CSV */

.modal-body.csv {
	display: grid;
	place-items: center;
}

.downloading-container {
	width: 182px;
	height: 154px;
	background-color: var(--background-color);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius);
}

.downloading {
	width: 100px;
	height: 72px;
	margin: 0 auto;
	display: grid;
	place-items: center;
	position: relative;
	overflow: hidden;
}

.custom-arrow {
	width: 24px;
	height: 34px;
	position: absolute;
	top: 0;
	background-color: rgba(var(--blue-02));
	animation-name: downloading;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
}

.custom-arrow.stop {
	animation-iteration-count: unset;
}

.custom-arrow:after {
	content: "";
	position: absolute;
	display: block;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	border-top: 25px solid rgba(var(--blue-02));
	border-left: 26px solid transparent;
	border-right: 26px solid transparent;
}

.bar {
	width: 80px;
	height: 4px;
	background-color: rgba(var(--blue-02));
	margin: 0 auto;
}

@keyframes downloading {
	0% {
		top: 0;
		opacity: 1;
	}
	50% {
		top: 110%;
		opacity: 0;
	}
	52% {
		top: -110%;
		opacity: 0;
	}
	100% {
		top: 0;
		opacity: 1;
	}
}

/* Cancel and Submit Button Container */

.modal-footer {
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
}

.modal-footer button {
	margin-left: 1rem;
	border-radius: var(--border-radius);
}

@keyframes slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0%);
	}
}

@media only screen and (width < 636px) {
	.modal-content {
		height: 100%;
		width: 100%;
	}

	.minimap-container {
		width: 100%;
	}

	.delete-prompt {
		flex-direction: column;
	}

	.delete-prompt .icon {
		font-size: 3rem;
	}
}
