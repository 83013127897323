/* Error */

.error {
	height: 374px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	color: rgba(var(--red));
	background-color: rgba(var(--red), 0.1);
	border-radius: var(--border-radius);
}

.error .icon {
	font-size: 100px;
}
