/* Device Info */

.device-info {
    margin-bottom: 2rem;
}

.device-info .device-name {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.device-info .device-name .icon {
    cursor: pointer;
}

.device-info .device-loc {
    font-size: 0.9rem;
}
