/* Graph Toggle */

.toggle {
	position: relative;
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.toggle .item {
	padding: 0.25rem;
	display: grid;
	place-items: center;
	font-size: 1.5rem;
	color: var(--gray-accent);
	background-color: var(--background-color);
	border-radius: var(--border-radius);
	transition: var(--tran-03);
}

.toggle .item.active {
	color: rgba(var(--blue-02));
	background-color: rgba(var(--blue-02), 0.2);
}

/* .item {
	height: 26px;
	padding: 2px 0;
	font-size: 0.8rem;
	text-align: center;
	cursor: pointer;
}

.toggle-indicator {
	height: 26px;
	position: absolute;
	left: 3px;
	background-color: rgba(var(--blue-02));
	border-radius: var(--border-radius);
	z-index: -1;
	transition: var(--tran-03);
}

.item.active {
	color: white;
}

.graph-configs .item,
.graph-configs .toggle-indicator {
	width: 80px;
}

.overview-configs .item,
.overview-configs .toggle-indicator {
	width: 172px;
}

.graph-configs .item:nth-child(1).active ~ .toggle-indicator {
	transform: translateX(calc(80px * 0));
}

.graph-configs .item:nth-child(2).active ~ .toggle-indicator {
	transform: translateX(calc(80px * 1));
}

.graph-configs .item:nth-child(3).active ~ .toggle-indicator {
	transform: translateX(calc(80px * 2));
}

.graph-configs .item:nth-child(4).active ~ .toggle-indicator {
	transform: translateX(calc(80px * 3));
}

.overview-configs .item:nth-child(1).active ~ .toggle-indicator {
	transform: translateX(calc(172px * 0));
}

.overview-configs .item:nth-child(2).active ~ .toggle-indicator {
	transform: translateX(calc(172px * 1));
} */
