/* Graph Configs */

.figure-configs {
	margin-bottom: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
}

/* Graphs */

.line,
.doughnut {
	display: grid;
	place-items: center;
}

.line-graph {
	width: 100%;
}

.doughnut-graph {
	width: 57%;
}

/* Tables */

.reading-table {
	margin-bottom: 1rem;
}

td.date {
	font-weight: 600;
	white-space: nowrap;
}

.reading {
	font-weight: 600;
	text-align: right;
}

.reading-table th:last-child {
	text-align: right;
}

.reading-table.multiple th,
.reading-table.multiple td {
	text-align: center;
}

@media only screen and (width < 735px) {
	.doughnut-graph {
		width: 80%;
	}
}

@media only screen and (width < 585px) {
	.doughnut-graph {
		width: 100%;
	}
}
