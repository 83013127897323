@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");

*,
::before,
::after {
	box-sizing: border-box;
}

html {
	line-height: 1.5;
	--webkit-text-size-adjust: 100%;
	font-family: "Montserrat", sans-serif;
}

:root {
	/* Colors */
	--background-color: rgb(245, 246, 250);
	--black: rgb(45, 58, 64);
	--gray-accent: rgb(217, 223, 237);
	--red: 249, 29, 31;

	--blue-01: 111, 219, 254;
	--blue-02: 109, 170, 252;
	--blue-03: 90, 115, 172;

	/* Measurements */
	--border-radius: 0.25rem;

	/* Transition */
	--tran-05: all 0.5s ease;
	--tran-03: all 0.3s ease;
	--tran-02: all 0.2s ease;
	--tran-02-delayed-10: all 0.2s ease 0.1s;
	--tran-02-delayed-45: all 0.2s ease 0.45s;

	/* Shadows */
	--shadow-01: 0px 0px 40px 9px rgba(0, 0, 0, 0.2);
	--shadow-02: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

/* Scrollbar Styles */

::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: gainsboro;
	transition: var(--tran-03);
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:focus {
	background-color: gray;
}

/* General Styles */

body {
	margin: 0;
	line-height: inherit;
	background-color: var(--background-color);
}

main {
	height: 100vh;
	width: 100vw;
	color: var(--black);
}

h1,
h2,
h3,
p {
	margin: 0;
	padding: 0;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

/* Input Styles */

button {
	cursor: pointer;
}

button,
input,
select,
textarea {
	margin: 0;
	padding: 0;
	font-family: inherit;
	font-size: 100%;
	line-height: inherit;
	color: inherit;
	background-color: transparent;
	border: 0;
	outline: 0;
}

/* Table Styles */

table {
	width: 100%;
	border-collapse: collapse;
}

tr {
	border-top: 1px solid var(--gray-accent);
}

thead tr {
	border-top: none;
}

tbody tr:hover {
	background-color: rgba(var(--blue-02), 0.1);
}

th {
	padding: 0.5rem;
	text-align: left;
	font-weight: 600;
}

td {
	padding: 0.5rem;
}

/* Date Range Classes */

input.inputBox {
	height: 32px;
	padding: 0 10px;
	text-align: center;
	vertical-align: center;
	font-size: 0.8rem;
	font-weight: 500;
	border: 1px solid var(--gray-accent);
	border-radius: var(--border-radius);
	cursor: pointer;
}

input.inputBox:hover {
	background-color: rgba(var(--blue-02), 0.2);
	border: 1px solid rgba(var(--blue-02));
	transition: var(--tran-03);
}

.calendarWrap {
	display: inline-block;
	position: relative;
}

.calendarElement {
	position: absolute;
	left: 0;
	transform: translateX(-46%);
	top: 40px;
	border: 1px solid #ccc;
	z-index: 999;
}

.rdrDayToday .rdrDayNumber span:after {
	background: rgb(var(--blue-03)) !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
	background: white !important;
}

/* Color Classes */

.good {
	background-color: rgba(51, 244, 108, 0.2);
	border: 1px solid rgba(51, 244, 108);
}

.fair {
	background-color: rgba(244, 245, 72, 0.2);
	border: 1px solid rgba(244, 245, 72);
}

.unhealthy-sg {
	background-color: rgba(245, 176, 69, 0.2);
	border: 1px solid rgba(245, 176, 69);
}

.very-unhealthy {
	background-color: rgba(245, 47, 47, 0.2);
	border: 1px solid rgba(245, 47, 47);
}

.acutely-unhealthy {
	background-color: rgba(143, 83, 221, 0.2);
	border: 1px solid rgba(143, 83, 221);
}

.emergency {
	background-color: rgba(173, 24, 47, 0.2);
	border: 1px solid rgba(173, 24, 47);
}

.text-green {
	color: rgba(11, 213, 72);
}

.text-red {
	color: rgba(245, 47, 47);
}

/* Card Styles */

.card {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: var(--border-radius);
}

.card-header {
	width: 100%;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card-header span {
	font-size: 0.8rem;
	font-weight: 500;
	color: gray;
}

/* Icons */

.icon {
	font-size: 1.5rem;
	color: var(--blue-02);
}

.device-marker {
	height: 35px;
	width: 35px;
	display: grid;
	place-items: center;
	border-radius: 50%;
	background-color: rgba(var(--blue-02), 0.3);
	cursor: pointer;
}

.device-marker-center {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: rgba(var(--blue-02));
}

.selected.device-marker {
	background-color: rgba(var(--red));
}

/* Buttons */

.btn {
	padding: 0.375rem 1.25rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	border-radius: var(--border-radius);
	transition: var(--tran-02);
	white-space: nowrap;
}

.btn.no-text {
	padding: 0.375rem 0.4rem;
}

.btn-primary {
	color: white;
	background-color: rgba(var(--blue-02));
}

.btn-primary:hover {
	background-color: rgba(var(--blue-02), 0.85);
}

.btn-secondary {
	color: rgba(var(--blue-02));
	border: 1px solid rgba(var(--blue-02));
}

.btn-secondary:hover {
	background-color: rgba(var(--blue-02), 0.1);
}

.btn-cancel {
	border: 1px solid transparent;
	color: rgba(var(--blue-02));
}

.btn-cancel:hover {
	color: rgba(var(--red));
	border: 1px solid rgba(var(--red));
}

/* Title */

.title {
	font-size: 1.25rem;
	font-weight: 500;
}

/* Community type */

.comm-type {
	margin-right: 5px;
	padding: 2px 5px;
	display: inline-block;
	font-size: 0.8rem;
	color: gray;
	border: 1px solid lightgray;
	border-radius: var(--border-radius);
}

/* Status */

.status {
	display: flex;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 500;
	color: var(--gray-accent);
}

.status div:first-child {
	height: 7px;
	width: 7px;
	margin-right: 5px;
	border-radius: 50%;
	background-color: var(--gray-accent);
}

.status.Online {
	color: rgba(var(--blue-02));
}

.status.Online div:first-child {
	background-color: rgba(var(--blue-02));
}

.status.Offline {
	color: rgba(var(--red));
}

.status.Offline div:first-child {
	background-color: rgba(var(--red));
}

table.Offline td {
	color: #ccc;
}

@media only screen and (width < 545px) {
	.calendarElement {
		left: 50%;
		transform: translateX(-50%);
	}
}
